<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Inventory -Bin Locations Report</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getSerialNumbersReport">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="4">
            <v-autocomplete
              v-model="options.BinCode"
              :items="BinCode"
              dense
              attach
              chips
              small-chips
              clearable
              deletable-chips
              label="Select Bin Loc"
              item-text="BinCode"
              item-value="BinCode"
              multiple
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              v-model="options.WhsCode"
              :items="WhsCode"
              dense
              attach
              chips
              small-chips
              clearable
              deletable-chips
              label="Select Warehouse"
              item-text="WhsName"
              item-value="WhsCode"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <!-- <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="serialsReports"
              :items-per-page="options.itemsPerPageOptions"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              class="elevation-1"
              :server-search="true"
              :footer-props="{
                itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }],
                showCurrentPage: true,
              }"
            >
              <template v-slot:item.Freezed="{ item }">
                <span v-if="item.Freezed">YES</span>
                <span v-else>No</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false">Close</v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    serialsReports: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    BinCode: [],
    WhsCode: [],
    headers: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Name", value: "item.ItemName" },
      { text: "Bin Location", value: "bin_location.BinCode" },
      { text: "Warehouse", value: "bin_location.warehouse.WhsName" },
      { text: "OnHand Qty", value: "OnHandQty" },
      { text: "Freezed", value: "Freezed" },
    ],
    options: {
      itemsPerPage: 15,
      page: 1,
      BinCode: null,
      WhsCode: null,
    },
    totalItems: 0,
  }),

  watch: {
    options: {
      handler() {
        this.getSerialNumbersReport();
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      if (this.search.length >= 3) {
        this.getSerialNumbersReport();
      }
    },
  },
  methods: {
    getSerialNumbersReport() {
      const self = this;
      this.loading = true;
      let url = `/bin-locations/reports?page=${self.options.page}&per_page=${self.options.itemsPerPage}`;
      if (this.options.BinCode) {
        url += `${url.includes("?") ? "&" : "?"}BinCode=${this.options.BinCode}`;
      }
      if (this.options.WhsCode) {
        url += `${url.includes("?") ? "&" : "?"}WhsCode=${this.options.WhsCode}`;
      }
      this.$store
        .dispatch("get", url)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.serialsReports = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    getBinCode() {
      const self = this;
      this.$store
        .dispatch("get", `/warehouses/bin-location`)
        .then((res) => {
          self.BinCode = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getWhsCode() {
      const self = this;
      this.$store
        .dispatch("get", `/warehouse`)
        .then((res) => {
          self.WhsCode = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getBinCode();
    this.getWhsCode();
    // this.getSerialNumbersReport();
  },
};
</script>
